import { CopyLockupList } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import CopyLockupModule, { CopyLockupDataProps } from '../CopyLockupModule';
import { container, marginContainer, wrap } from './CopyLockupListModule.module.css';

export default function CopyLockupListModule({
  blobs,
  childrenCollapseOnMobile,
  className,
  columns,
  columnsDesktop,
  data
}) {
  const {
    buttonCTACopy,
    buttonCTATrackingClass,
    buttonCTAUrl,
    childrenLayout,
    copyLockup,
    hasMarginBottom,
    hideWavyLine,
    linkCTACopy,
    linkCTATrackingClass,
    linkCTAUrl,
    title,
    tagline
  } = data;

  const classes = classNames(className, container, wrap, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={classes}>
      {blobs}
      <CopyLockupList
        buttonCTACopy={buttonCTACopy}
        buttonCTATrackingClass={buttonCTATrackingClass}
        buttonCTAUrl={buttonCTAUrl}
        childrenCollapseOnMobile={childrenCollapseOnMobile}
        childrenLayout={childrenLayout}
        className={className}
        columns={columns}
        columnsDesktop={columnsDesktop}
        hideWavyLine={hideWavyLine}
        linkCTACopy={linkCTACopy}
        linkCTATrackingClass={linkCTATrackingClass}
        linkCTAUrl={linkCTAUrl}
        tagline={tagline}
        title={title}
      >
        {copyLockup.map((item) => <CopyLockupModule key={item.title} data={item} />)}
      </CopyLockupList>
    </div>
  );
}

export const CopyLockupListDataPropsObject = {
  buttonCTACopy          : PropTypes.string,
  buttonCTATrackingClass : PropTypes.string,
  buttonCTAUrl           : PropTypes.string,
  childrenLayout         : PropTypes.string,
  copyLockup             : PropTypes.arrayOf(CopyLockupDataProps).isRequired,
  hasMarginBottom        : PropTypes.bool,
  hideWavyLine           : PropTypes.bool,
  linkCTACopy            : PropTypes.string,
  linkCTATrackingClass   : PropTypes.string,
  linkCTAUrl             : PropTypes.string,
  tagline                : PropTypes.string,
  title                  : PropTypes.string
};

CopyLockupListModule.propTypes = {
  blobs                    : PropTypes.node,
  childrenCollapseOnMobile : PropTypes.bool,
  className                : PropTypes.string,
  columns                  : PropTypes.oneOf([1, 2]),
  columnsDesktop           : PropTypes.oneOf([1, 2, 3]),
  data                     : PropTypes.shape(CopyLockupListDataPropsObject).isRequired
};

CopyLockupListModule.defaultProps = {
  blobs                    : null,
  childrenCollapseOnMobile : false,
  className                : null,
  columns                  : 1,
  columnsDesktop           : 3
};

export const CopyLockupListModuleData = graphql`
  fragment CopyLockupListModuleData on ContentfulComponentCopyLockupList {
    buttonCTACopy
    buttonCTATrackingClass
    buttonCTAUrl
    childrenLayout
    copyLockup {
      ...CopyLockupModuleData
    }
    hasMarginBottom
    hideWavyLine
    linkCTACopy
    linkCTATrackingClass
    linkCTAUrl
    tagline
    title
  }
`;
