import { CopyLockup, useClient } from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import ModuleMarkdown from '../ModuleMarkdown';
import { fallback } from './CopyLockupModule.module.css';

export const AddPhotoIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations//AddPhotoIllustration'));
const AspectRatioIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/AspectRatioIcon'));
const AdminIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/AdminIcon'));
const BookIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/BookIcon'));
const CelebrationsIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/CelebrationsIcon'));
const CoinsIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/CoinsIcon'));
const CommentingIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/CommentingIcon'));
const CommuterIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/CommuterIcon'));
const CompanyNewsIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/CompanyNewsIcon'));
const CreateIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/CreateIllustration'));
const CustomerSupportIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/CustomerSupportIcon'));
const CustomizationIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/CustomizationIcon'));
const CustomizeIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/CustomizeIllustration'));
const DownloadIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/DownloadIcon'));
const DragDropIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/DragDropIllustration'));
const DriveTrafficIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/DriveTrafficIllustration'));
const EngineeringIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/EngineeringIcon'));
const EmployeeOneIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/EmployeeOneIllustration'));
const EmployeeThreeIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/EmployeeThreeIllustration'));
const EmployeeTwoIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/EmployeeTwoIllustration'));
const EngageIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/EngageIllustration'));
const EventRecapsIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/EventRecapsIcon'));
const ExecIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/ExecIcon'));
const ExportIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/ExportIcon'));
const FontIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/FontIcon'));
const FontUploadIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/FontUploadIcon'));
const GraphIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/GraphIcon'));
const GrowthResultsIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/GrowthResultsIllustration'));
const HandPhoneIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/HandPhoneIllustration'));
const HRIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/HRIcon'));
const ImpressIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/ImpressIllustration'));
const IncreaseSalesIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/IncreaseSalesIllustration'));
const LaptopIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/LaptopIllustration'));
const MagnifyingGlassIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/MagnifyingGlassIcon'));
const MarketingTeamIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/MarketingTeamIcon'));
const MediaPlayerIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/MediaPlayerIcon'));
const MediaTypesIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/MediaTypesIcon'));
const MedicalInsuranceIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/MedicalInsuranceIcon'));
const MemorableVideoIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/MemorableVideoIllustration'));
const MobileIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/MobileIcon'));
const MoneyIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/MoneyIcon'));
const MusicLibraryIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/MusicLibraryIcon'));
const MusicLibraryIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/MusicLibraryIllustration'));
const NetworkIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/NetworkIcon'));
const OnboardingIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/OnboardingIcon'));
const OperationsIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/OperationsIcon'));
const PartyIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/PartyIllustration'));
const PresentationsIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/PresentationsIcon'));
const PieChartIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/PieChartIcon'));
const PiggyBankIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/PiggyBankIcon'));
const PlayButtonIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/PlayButtonIcon'));
const ProduceIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/ProduceIllustration'));
const ProductIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/ProductIcon'));
const RealEstateListingIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/RealEstateListingIcon'));
const RibbonIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/RibbonIcon'));
const RoiIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/RoiIcon'));
const SalesIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/SalesIcon'));
const SavedBrandIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/SavedBrandIcon'));
const ScreenRecordIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/ScreenRecordIcon'));
const SecureIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/SecureIcon'));
const ShoppingCartIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/ShoppingCartIcon'));
const SnacksIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/SnacksIcon'));
const SocialEventsIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/SocialEventsIcon'));
const SocialNetworkIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/SocialNetworkIcon'));
const SocialShareIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/SocialShareIcon'));
const SpeechBubblesIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/SpeechBubblesIcon'));
const SweaterIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/SweaterIcon'));
const StepOneIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/StepOneIcon'));
const StepThreeIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/StepThreeIcon'));
const StepTwoIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/StepTwoIcon'));
const StockLibraryIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/StockLibraryIcon'));
const StoreIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/StoreIcon'));
const TemplatesIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/TemplatesIcon'));
const TestimonialOneIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/TestimonialOneIllustration'));
const TestimonialThreeIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/TestimonialThreeIllustration'));
const TestimonialTwoIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/TestimonialTwoIllustration'));
const TextAnimationIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/TextAnimationIcon'));
const TikTokIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/TikTokIcon'));
const TrainingsIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/TrainingsIcon'));
const TravelIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/TravelIcon'));
const TrimIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/TrimIcon'));
const UnlimitedIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/UnlimitedIcon'));
const VoiceOverIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/VoiceOverIcon'));
const WatermarkIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/WatermarkIcon'));
const WatermarkIllustration = React.lazy(() => import('@animoto/components/lib/es/Illustrations/WatermarkIllustration'));
const WebcamRecordIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/WebcamRecordIcon'));
const WelcomingsIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/WelcomingsIcon'));
const WhiteLabelIcon = React.lazy(() => import('@animoto/components/lib/es/MarketingIcon/WhiteLabelIcon'));

const iconMap = {
  AddPhotoIllustration,
  AdminIcon,
  AspectRatioIcon,
  BookIcon,
  CelebrationsIcon,
  CoinsIcon,
  CommentingIcon,
  CommuterIcon,
  CompanyNewsIcon,
  CreateIllustration,
  CustomizationIcon,
  CustomizeIllustration,
  CustomerSupportIcon,
  DragDropIllustration,
  DownloadIcon,
  DriveTrafficIllustration,
  EngineeringIcon,
  EmployeeOneIllustration,
  EmployeeThreeIllustration,
  EmployeeTwoIllustration,
  EngageIllustration,
  EventRecapsIcon,
  ExecIcon,
  ExportIcon,
  FontIcon,
  FontUploadIcon,
  GraphIcon,
  GrowthResultsIllustration,
  HandPhoneIllustration,
  HRIcon,
  ImpressIllustration,
  IncreaseSalesIllustration,
  LaptopIllustration,
  MagnifyingGlassIcon,
  MarketingTeamIcon,
  MediaPlayerIcon,
  MediaTypesIcon,
  MedicalInsuranceIcon,
  MemorableVideoIllustration,
  MobileIcon,
  MoneyIcon,
  MusicLibraryIcon,
  MusicLibraryIllustration,
  NetworkIcon,
  OnboardingIcon,
  OperationsIcon,
  PartyIllustration,
  PieChartIcon,
  PiggyBankIcon,
  PlayButtonIcon,
  PresentationsIcon,
  ProduceIllustration,
  ProductIcon,
  RealEstateListingIcon,
  RibbonIcon,
  RoiIcon,
  SalesIcon,
  SavedBrandIcon,
  ScreenRecordIcon,
  SecureIcon,
  ShoppingCartIcon,
  SnacksIcon,
  SocialEventsIcon,
  SocialNetworkIcon,
  SocialShareIcon,
  SpeechBubblesIcon,
  StepOneIcon,
  StepThreeIcon,
  StepTwoIcon,
  StockLibraryIcon,
  StoreIcon,
  SweaterIcon,
  TemplatesIcon,
  TestimonialOneIllustration,
  TestimonialThreeIllustration,
  TestimonialTwoIllustration,
  TextAnimationIcon,
  TikTokIcon,
  TrainingsIcon,
  TravelIcon,
  TrimIcon,
  UnlimitedIcon,
  VoiceOverIcon,
  WatermarkIcon,
  WatermarkIllustration,
  WelcomingsIcon,
  WebcamRecordIcon,
  WhiteLabelIcon
};

export default function CopyLockupModule({ collapseOnMobile, data, layout }) {
  const {
    canTwitterShare,
    copy,
    ctaCopy,
    ctaTrackingClass,
    ctaUrl,
    icon,
    illustrationSize,
    image,
    imageAlt,
    imageSize,
    mobileCtaTrackingClass,
    title
  } = data;

  const isClient = useClient();

  const getIconElement = (iconName) => {
    const IconElement = iconMap[iconName];
    if (isClient) {
      return (
        <Suspense fallback={<span className={fallback} />}>
          <IconElement illustrationSize={illustrationSize} />
        </Suspense>
      );
    }
    return null;
  };

  return (
    <CopyLockup
      canTwitterShare={canTwitterShare}
      collapseOnMobile={collapseOnMobile}
      copy={copy && <ModuleMarkdown>{copy.childMarkdownRemark.htmlAst}</ModuleMarkdown>}
      ctaCopy={ctaCopy}
      ctaTrackingClass={ctaTrackingClass}
      ctaUrl={ctaUrl}
      icon={icon && getIconElement(icon)}
      illustrationSize={illustrationSize}
      imageAlt={imageAlt}
      imageSize={imageSize}
      imageSrcFluid={image && image.fluid}
      layout={layout}
      mobileCtaTrackingClass={mobileCtaTrackingClass}
      title={title}
    />
  );
}

export const CopyLockupDataProps = PropTypes.shape({
  canTwitterShare : PropTypes.bool,
  copy            : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }).isRequired,
  ctaCopy          : PropTypes.string,
  ctaTrackingClass : PropTypes.string,
  ctaUrl           : PropTypes.string,
  icon             : PropTypes.string,
  illustrationSize : PropTypes.string,
  image            : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }),
  imageAlt               : PropTypes.string,
  imageSize              : PropTypes.string,
  mobileCtaTrackingClass : PropTypes.string,
  title                  : PropTypes.string.isRequired
});

CopyLockupModule.propTypes = {
  collapseOnMobile : PropTypes.bool,
  data             : CopyLockupDataProps.isRequired,
  layout           : PropTypes.string
};

CopyLockupModule.defaultProps = {
  collapseOnMobile : false,
  layout           : ''
};

export const CopyLockupModuleData = graphql`
  fragment CopyLockupModuleData on ContentfulComponentCopyLockup {
    canTwitterShare
    copy {
      childMarkdownRemark {
        htmlAst
      }
    }
    ctaCopy
    ctaTrackingClass
    ctaUrl
    icon
    illustrationSize
    image {
      fluid(quality: 80, maxWidth: 460, maxHeight: 430) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    imageAlt
    imageSize
    mobileCtaTrackingClass
    title
  }
`;
