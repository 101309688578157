// extracted by mini-css-extract-plugin
export var base = "CopyLockupListModule-module--base--2qrj7";
export var rightAlignedContainer = "CopyLockupListModule-module--rightAlignedContainer--jcupN";
export var rightAlignedContainerSmall = "CopyLockupListModule-module--rightAlignedContainerSmall--2IM8V";
export var leftAlignedContainer = "CopyLockupListModule-module--leftAlignedContainer--2Kilk";
export var blogContainer = "CopyLockupListModule-module--blogContainer--1hPEF CopyLockupListModule-module--base--2qrj7";
export var container = "CopyLockupListModule-module--container--1QIum CopyLockupListModule-module--base--2qrj7 CopyLockupListModule-module--container--1QIum CopyLockupListModule-module--base--2qrj7";
export var largeContainer = "CopyLockupListModule-module--largeContainer--s2WaV CopyLockupListModule-module--base--2qrj7";
export var mobileSmallPadding = "CopyLockupListModule-module--mobileSmallPadding--2Lfjx";
export var fullWidthMobile = "CopyLockupListModule-module--fullWidthMobile--1jgtt";
export var gridWithSidebar = "CopyLockupListModule-module--gridWithSidebar--vGdBp";
export var noMaxWidth = "CopyLockupListModule-module--noMaxWidth--22GdO";
export var wrap = "CopyLockupListModule-module--wrap--1tXDE";
export var marginContainer = "CopyLockupListModule-module--marginContainer--2RtRu";